/* fareCard */
.fareCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 35rem;
  padding: 1rem;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* cursor: pointer; */
  transition: all 0.15s ease-in-out;
  text-align: center;
  justify-content: space-between;
  /* height: 16rem; */
  /* height: 100%; */
  position: relative;
  height: fit-content;
}

.fareCard:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(1.03);
}

.fareCard__top,
.fareCard__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.fareCard__badge {
  width: 70px;
  position: absolute;
  right: -25px;
  top: -25px;
}

.fareCard__badge > img {
  width: 100%;
}

.arrow {
  opacity: 0.4;
}

.destName {
  /* width: 6rem; */
  /* height: 6rem; */
}

.airline__logo {
  /* width: 50px; */
}

.callingNumber {
  transition: all 0.15s ease-in-out;
}

.callingNumber:hover {
  color: red;
}

.fareCard__footerr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 1rem;
}

.airline__logoForPC {
  display: block;
}

.airline__logoForMobile {
  display: none;
}

.fareCard__top__divider{
  width: 100%;
  display: none;
}

@media screen and (max-width: 1200px) {
  /* fareCard */
  .fareCard {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 18rem;
    padding: 1rem;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* cursor: pointer; */
    transition: all 0.15s ease-in-out;
    /* height: 100%; */
  }

  .fareCard__top,
  .fareCard__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .arrow {
    transform: rotate(90deg);
  }


.airline__logoForPC {
  display: none;
}

.airline__logoForMobile {
  display: block;
}

.fareCard__top__divider{
  width: 100%;
  display: block;
}
}
