.bookPage {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 900px) {
  .bookPage {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .book__img {
    display: none;
  }
}

.gridSectionOne {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book__img {
  width: 100%;
  padding: 0 1rem;
}

.book__img > img {
  width: 100%;
  border-radius: 10px;
}

.selectedFare {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 1rem;
  border-radius: 10px;
  overflow: hidden;
  width: 560px;
  height: fit-content;
}

.selectedFare__header {
  background-color: #00b6f2;
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: center;
}

.selectedFare__body {
  padding: 0.5rem 1rem 1rem 1rem;
  opacity: 0.8;
}

.selectedFare__grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 8px 0;
}

/* customerDetails */
.customerDetails {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 1rem;
  border-radius: 10px;
  overflow: hidden;
  width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* customerDetails__header */
.customerDetails__header {
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: center;
}

.customerDetails__bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* customerDetails__body */
.customerDetails__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

@media screen and (max-width: 800px) {
  .selectedFare,
  .customerDetails {
    width: 400px;
  }
}

@media screen and (max-width: 700px) {
  .selectedFare,
  .customerDetails {
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .selectedFare,
  .customerDetails {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .selectedFare,
  .customerDetails {
    width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .selectedFare,
  .customerDetails {
    width: 350px;
  }
}

@media screen and (max-width: 350px) {
  .selectedFare,
  .customerDetails {
    width: 320px;
  }
}

/* loader */
.loaderContainer {
  width: 100%;
  display: flex;
  background-color: white;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #cbcbcbac;
  border-radius: 50%;
  border-top: 16px solid #000000;
  border-bottom: 16px solid #000000;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
