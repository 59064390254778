.bannerContainer {
  background-color: rgba(255, 255, 255, 0.911);
  border-radius: 10px;
  border: 2px solid #00b5f299;
}

.banner__form {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.countsAndType {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.counts {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media screen and (max-width: 1100px) {
  .banner__form {
    display: flex;
    flex-direction: column;
  }

  .banner {
    padding: 3rem 0 2rem 0;
  }
}

.flexVerical {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* searchField */
.searchField {
  font-size: 1rem;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  opacity: 0.9;
  border-radius: 5px;
  margin: 0.3rem;
  color: rgb(105, 105, 105);
  transition: all 0.2s ease-in-out;
}

/* dialog__inquiry */
.dialog__inquiry {
  margin: 0 1rem 1rem 1rem;
  text-align: center;
  position: relative;
  top: -10px;
}

.closeBtn {
  display: flex;
  justify-content: end;
  width: 100%;
}

.dialog__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchEngine_name {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 1100px) {
  .airlineSearch {
    display: none;
  }
}
